import React, { useState, useEffect } from "react";
import ProfileTabs from "../../../../component/Tabs/Tabs";
import EducationalInfo from "../../../Profile/EducationalInfo/EducationalInfo";
import EmploymentHistory from "../../../Profile/EmploymentHistory/EmploymentHistory";
import FinancialInfo from "../../../Profile/FinancialInfo/FinancialInfo";
import "../../../Profile/Profile.scss";
import PersonalInfo from "../../../Profile/PersonalInfo/PersonalInfo";
import ContactInfo from "../../../Profile/ContactInfo/ContactInfo";
import EmployeeIdentification from "../../../Profile/EmployeeIdentification/EmployeeIdentification";
import * as actions from "../../../../Redux/Actions";
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import moment from "moment";
import { icons } from "../../../../assets";
import useLoadingIndicator from "../../../../component/Loader/Loader";
import Skills from "../../../Profile/Skills/Skills";
import Button from "../../../../component/Buttons/Button";
import { useNavigate } from "react-router-dom";
import ModalComponents from "../../../../component/ModalComponent/ModalComponent";
import ReasonForReSend from "../DbSidebar/ReasonForResend";
import ProfileInfo from "./ProfileInfo";

const Profile = (props: any) => {
  const {profileId,profileInfo,setProfileInfo,personalInfoTab,
    setPersonalInfoTab,contactInfoTab,setContactInfoTab,
    employeeIdentificationTab,setemployeeIdentificationTab,
    financialInfoTab,setFinancialInfoTab,educationalInfo,
    setEducationalInfo,employmentHistory,setEmploymentHistory,skills,setSkills} =props;

  const role = localStorage.getItem("role");
  const CheckIsHrManager = role && role.includes("employee-db-hrmanager");
  const selectedId = localStorage.getItem("selectedId");
  const employeeDbChecker =  role?.includes("employee-db-checker");
  const [employeeDetails, setEmployeeDetails] = useState<any>({
    candidateName: "",
    lastname: "",
    designation: "",
    emailId: "",
    mobileNumber: "",
    dateOfBirth: "",
    alternateNumber: "",
    passportNumber: "",
    currentAddress1: "",
    currentAddress2: "",
    currentAddress3: "",
    permanentAddress1: "",
    permanentAddress2: "",
    permanentAddress3: "",
    currentPinCode: "",
    permanentPinCode: "",
    middleName: "",
    currentState: "",
    permanentState: "",
    currentCountry: "India",
    permanentCountry: "India",
    currentDistrict: "",
    permanentDistrict: "",
    employeeId: "",
    personalMail: "",
    candidatePhoto: "",
    resume: "",
    dateOfJoining: "",
    aadharNumber: "",
    pan: "",
    title: "",
    level: "",
    band: "",
    department: "",
    clientName: "",
    workLocation: "",
    reportingManagerName: "",
    pfNumber: "",
    insuranceNumber: "",
    uanNumber: "",
    experience: "",
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
    emergencyContactNumber: "",
    panCardImage: "",
    aadharCardImage: "",
    eId: "",
    recordStatus: "",
    permanentAddressProof: "",
    currentAddressProof: "",
    cancelledCheque: "",
    status: "",
    isFresher: "",
    isSameComunicationAdderess: "",
    bloodGroup: "",
    hrbpName: "",
    hrbpRef: "",
    totalExperience: "",
    previousExperience: "",
    organisationExperience: "",
    summary: [],
    summaryReminderCount: 0,
    languageDetails:[]
  });
  const [images, setImages] = React.useState([]);
  const [displayPicture, setdisplayPicture] = React.useState(null);
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();

  const dispatch = useDispatch();

  const editEmployeeDetails = useSelector(
    (state: any) => state.employeeReducer.editEmployeeData
  );

  const handlePersonalInfo = () => {
    setPersonalInfoTab(true);
    setContactInfoTab(false);
    setemployeeIdentificationTab(false);
    setFinancialInfoTab(false);
    setEducationalInfo(false);
    setEmploymentHistory(false);
    setSkills(false);
    setProfileInfo(false);
  };

  const handleContactInfo = () => {
    setContactInfoTab(true);
    setPersonalInfoTab(false);
    setemployeeIdentificationTab(false);
    setFinancialInfoTab(false);
    setEducationalInfo(false);
    setEmploymentHistory(false);
    setSkills(false);
    setProfileInfo(false);
  };

  const handleEmployeeIdentificationInfo = () => {
    setemployeeIdentificationTab(true);
    setPersonalInfoTab(false);
    setContactInfoTab(false);
    setFinancialInfoTab(false);
    setEducationalInfo(false);
    setEmploymentHistory(false);
    setSkills(false);
    setProfileInfo(false);
  };

  const handleFinancialInfo = () => {
    setFinancialInfoTab(true);
    setContactInfoTab(false);
    setPersonalInfoTab(false);
    setemployeeIdentificationTab(false);
    setEducationalInfo(false);
    setEmploymentHistory(false);
    setSkills(false);
    setProfileInfo(false);
  };

  const handleEducationalInfo = () => {
    setEducationalInfo(true);
    setFinancialInfoTab(false);
    setContactInfoTab(false);
    setPersonalInfoTab(false);
    setemployeeIdentificationTab(false);
    setEmploymentHistory(false);
    setSkills(false);
    setProfileInfo(false);
  };

  const handleEmploymentHistory = () => {
    setEmploymentHistory(true);
    setEducationalInfo(false);
    setFinancialInfoTab(false);
    setContactInfoTab(false);
    setPersonalInfoTab(false);
    setemployeeIdentificationTab(false);
    setSkills(false);
    setProfileInfo(false);
  };

  const handleSkills = () => {
    setSkills(true);
    setEmploymentHistory(false);
    setEducationalInfo(false);
    setFinancialInfoTab(false);
    setContactInfoTab(false);
    setPersonalInfoTab(false);
    setemployeeIdentificationTab(false);
    setProfileInfo(false);
  };

  const handleProfileInfo = () => {
    setSkills(false);
    setEmploymentHistory(false);
    setEducationalInfo(false);
    setFinancialInfoTab(false);
    setContactInfoTab(false);
    setPersonalInfoTab(false);
    setemployeeIdentificationTab(false);
    setProfileInfo(true);
  };
  useEffect(() => {
    const pathname = window.location.pathname;
    const id = pathname?.split("/").pop();
    if (id) {
      handleProfileInfo();
    }
  }, []);
  const setProfile = (employeeData: any) => {
    setEmployeeDetails({
      recordStatus:
        employeeData && employeeData.recordStatus
          ? employeeData.recordStatus
          : "",
      status: employeeData && employeeData.status ? employeeData.status : "",
      candidateName:
        employeeData && employeeData.candidateName
          ? employeeData.candidateName
          : "",
      lastname:
        employeeData && employeeData.lastname ? employeeData.lastname : "",
      designation:
        employeeData && employeeData.designation
          ? employeeData.designation
          : "",
      emailId: employeeData && employeeData.emailId ? employeeData.emailId : "",
      mobileNumber:
        employeeData && employeeData.mobileNumber
          ? employeeData.mobileNumber
          : "",
      dateOfBirth:
        employeeData && employeeData.dateOfBirth
          ? moment(employeeData.dateOfBirth).format("DD/MMM/YYYY")
          : "",
      alternateNumber:
        employeeData && employeeData.alternateNumber
          ? employeeData.alternateNumber
          : "",
      passportNumber:
        employeeData && employeeData.passportNumber
          ? employeeData.passportNumber
          : "",
      currentAddress1:
        employeeData && employeeData.currentAddress1
          ? employeeData.currentAddress1
          : "",
      currentAddress2:
        employeeData && employeeData.currentAddress2
          ? employeeData.currentAddress2
          : "",
      currentAddress3:
        employeeData && employeeData.currentAddress3
          ? employeeData.currentAddress3
          : "",
      permanentAddress1:
        employeeData && employeeData.permanentAddress1
          ? employeeData.permanentAddress1
          : "",
      permanentAddress2:
        employeeData && employeeData.permanentAddress2
          ? employeeData.permanentAddress2
          : "",
      permanentAddress3:
        employeeData && employeeData.permanentAddress3
          ? employeeData.permanentAddress3
          : "",
      currentPinCode:
        employeeData && employeeData.currentPinCode
          ? employeeData.currentPinCode
          : "",
      permanentPinCode:
        employeeData && employeeData.permanentPinCode
          ? employeeData.permanentPinCode
          : "",
      middleName:
        employeeData && employeeData.middleName ? employeeData.middleName : "",
      currentState:
        employeeData && employeeData.currentState
          ? employeeData.currentState
          : "",
      permanentState:
        employeeData && employeeData.permanentState
          ? employeeData.permanentState
          : "",
      currentCountry:
        employeeData && employeeData.currentCountry
          ? employeeData.currentCountry
          : "",
      permanentCountry:
        employeeData && employeeData.permanentCountry
          ? employeeData.permanentCountry
          : "",
      employeeId:
        employeeData && employeeData.employeeId ? employeeData.employeeId : "",
      personalMail:
        employeeData && employeeData.personalMail
          ? employeeData.personalMail
          : "",
      currentDistrict:
        employeeData && employeeData.currentDistrict
          ? employeeData.currentDistrict
          : "",
      permanentDistrict:
        employeeData && employeeData.permanentDistrict
          ? employeeData.permanentDistrict
          : "",
      candidatePhoto:
        employeeData && employeeData.candidatePhoto
          ? employeeData.candidatePhoto
          : "",
      resume: employeeData && employeeData.resume ? employeeData.resume : "",
      dateOfJoining:
        employeeData && employeeData.dateOfJoining
          ? moment(employeeData.dateOfJoining).format("DD/MMM/YYYY")
          : "",
      level: employeeData && employeeData.level ? employeeData.level : "",
      band: employeeData && employeeData.band ? employeeData.band : "",
      department:
        employeeData && employeeData.department ? employeeData.department : "",
      clientName:
        employeeData && employeeData.clientName ? employeeData.clientName : "",
      workLocation:
        employeeData && employeeData.workLocation
          ? employeeData.workLocation
          : "",
      reportingManagerName:
        employeeData && employeeData?.Manager[0]
          ? employeeData?.Manager[0]?.candidateName +
            " " +
            employeeData?.Manager[0]?.middleName +
            " " +
            employeeData?.Manager[0]?.lastname +
            "-" +
            employeeData?.Manager[0]?.employeeId
          : "",
      pfNumber:
        employeeData && employeeData.pfNumber ? employeeData.pfNumber : "",
      insuranceNumber:
        employeeData && employeeData.insuranceNumber
          ? employeeData.insuranceNumber
          : "",
      uanNumber:
        employeeData && employeeData.uanNumber ? employeeData.uanNumber : "",
      experience:
        employeeData && employeeData.experience ? employeeData.experience : "",
      bankName:
        employeeData && employeeData.bankName ? employeeData.bankName : "",
      accountHolderName:
        employeeData && employeeData.accountHolderName
          ? employeeData.accountHolderName
          : "",
      accountNumber:
        employeeData && employeeData.accountNumber
          ? employeeData.accountNumber
          : "",
      ifscCode:
        employeeData && employeeData.ifscCode ? employeeData.ifscCode : "",
      emergencyContactNumber:
        employeeData && employeeData.emergencyContactNumber
          ? employeeData.emergencyContactNumber
          : "",
      aadharNumber:
        employeeData && employeeData.aadharNumber
          ? employeeData.aadharNumber
          : "",
      pan: employeeData && employeeData.pan ? employeeData.pan : "",
      aadharCardImage:
        employeeData && employeeData.aadharCardImage
          ? employeeData.aadharCardImage
          : "",
      panCardImage:
        employeeData && employeeData.panCardImage
          ? employeeData.panCardImage
          : "",
      title: employeeData && employeeData.title ? employeeData.title : "",
      eId: employeeData && employeeData._id ? employeeData._id : "",
      permanentAddressProof:
        employeeData && employeeData.permanentAddressProof
          ? employeeData.permanentAddressProof
          : "",
      currentAddressProof:
        employeeData && employeeData.currentAddressProof
          ? employeeData.currentAddressProof
          : "",
      cancelledCheque:
        employeeData && employeeData.cancelledCheque
          ? employeeData.cancelledCheque
          : "",
      isFresher:
        employeeData && employeeData.isFresher ? employeeData.isFresher : "",
      isSameComunicationAdderess:
        employeeData && employeeData.isSameComunicationAdderess
          ? employeeData.isSameComunicationAdderess
          : "",
      bloodGroup:
        employeeData && employeeData.bloodGroup ? employeeData.bloodGroup : "",
      hrbpName:
        employeeData && employeeData?.Hrbp[0]
          ? employeeData?.Hrbp[0]?.candidateName +
            " " +
            employeeData?.Hrbp[0]?.middleName +
            " " +
            employeeData?.Hrbp[0]?.lastname +
            "-" +
            employeeData?.Hrbp[0]?.employeeId
          : "",
      hrbpRef: employeeData && employeeData.hrbpRef ? employeeData.hrbpRef : "",
      totalExperience: employeeData && employeeData.totalExperience,
      previousExperience: employeeData && employeeData.previousExperience,
      organisationExperience:
        employeeData && employeeData.organisationExperience,
      currentLocation: employeeData && employeeData?.currentLocation,
      summary: employeeData && employeeData?.summary,
      summaryReminderCount: employeeData && employeeData.summaryReminderCount,
      languageDetails:employeeData && employeeData.languageDetails
    });

    setdisplayPicture(
      employeeData?.candidatePhoto?.document_path_url
        ? employeeData.candidatePhoto.document_path_url
        : employeeData?.candidatePhoto?.candidatePhoto?.document_path_url
        ? employeeData.candidatePhoto.candidatePhoto.document_path_url
        : icons.default_avatar
    );
    setImages([]);
  };

  const [showReason, setShowReason] = useState(false);
  const handleReason = () => {
    setShowReason(true);
  };
  const hideModal = () => {
    setShowReason(false);
  };
  const getApiDispatch = () => {
    const id = profileId ? profileId : localStorage.getItem('selectedId')
    dispatch(
      actions.getemployeeDetailsAction(id, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              setProfile(res.data[0]);
              hideLoadingIndicator();
            }
          }
        }
      })
    );
  };
  const employeeBulkUpload = useSelector(
    (state: any) => state.employeeReducer.employeeBulkUploadDetails
  );
  useEffect(() => {
    showLoadingIndicator();
    getApiDispatch();
  }, [selectedId,profileId]);

  useEffect(() => {
    getApiDispatch();
  }, [editEmployeeDetails,employeeBulkUpload]);

  const {
    recordStatus,
    aadharNumber,
    candidateName,
    middleName,
    lastname,
    emailId,
    designation,
    title,
    dateOfBirth,
    pan,
    passportNumber,
    status,
    aadharCardImage,
    panCardImage,
    personalMail,
    mobileNumber,
    alternateNumber,
    emergencyContactNumber,
    emergencyContactName,
    currentAddress1,
    currentAddress2,
    currentAddress3,
    permanentAddress1,
    permanentAddress2,
    permanentAddress3,
    currentCountry,
    permanentCountry,
    currentState,
    currentPinCode,
    currentDistrict,
    permanentDistrict,
    permanentState,
    permanentPinCode,
    permanentAddressProof,
    currentAddressProof,
    employeeId,
    dateOfJoining,
    department,
    level,
    band,
    experience,
    clientName,
    workLocation,
    reportingManagerName,
    pfNumber,
    uanNumber,
    bankName,
    accountNumber,
    accountHolderName,
    ifscCode,
    insuranceNumber,
    cancelledCheque,
    isFresher,
    resume,
    isSameComunicationAdderess,
    eId,
    bloodGroup,
    hrbpName,
    hrbpRef,
    organisationExperience,
    previousExperience,
    totalExperience,
    currentLocation,
    summary,
    summaryReminderCount,
    languageDetails
  } = employeeDetails;

  let navigate = useNavigate();
  const handleStatus = (statusData: any) => {
    const confirmed = window.confirm(
      statusData == "Approved"
        ? "Are you sure you want to approve? Employee will be no longer able to update their details once approved."
        : statusData == "Published"
        ? "Are you sure You want to Publish?"
        : statusData == "Re-Published"
        ? "Are you sure You want to Re-Publish?"
        : ""
    );
    if (confirmed) {
      showLoadingIndicator();
      dispatch(
        actions.editEmployeeDataAction(
          {
            eId: eId,
            updatedBy: loginId,
            recordStatus: statusData,
          },
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                hideLoadingIndicator();
                if (res.data.recordStatus == "Approved") {
                  showSuccessToastMessage("Approved Successfully!");
                } else if (res.data.recordStatus == "Published") {
                  showSuccessToastMessage("Published Successfully!");
                } else if (res.data.recordStatus == "Re-Published") {
                  showSuccessToastMessage("Re-Published Successfully!");
                }
                {
                  () => navigate("/");
                }
              }
            }
          }
        )
      );
    } else {
    }
  };

  const profileInfoDisplayCondition: any =
    localStorage.getItem("role")?.includes("hr-ta") || loginId == hrbpRef;

  return (
    <>
      {loadingComponent()}
      <div className="profile-tabs-container">
      <ProfileTabs
          title="Profile Info"
          onClick={handleProfileInfo}
          active={profileInfo}
        />
        <ProfileTabs
          title="Personal Info"
          onClick={handlePersonalInfo}
          active={personalInfoTab}
        />

        <ProfileTabs
          title="Contact Info"
          onClick={handleContactInfo}
          active={contactInfoTab}
        />

        <ProfileTabs
          title="Employee Identification"
          onClick={handleEmployeeIdentificationInfo}
          active={employeeIdentificationTab}
        />

        <ProfileTabs
          title="Financial Info"
          onClick={handleFinancialInfo}
          active={financialInfoTab}
        />

        <ProfileTabs
          title="Educational Info"
          onClick={handleEducationalInfo}
          active={educationalInfo}
        />

        <ProfileTabs
          title="Employment History"
          onClick={handleEmploymentHistory}
          active={employmentHistory}
        />

        <ProfileTabs title="Skills" onClick={handleSkills} active={skills} />

        {/* {profileInfoDisplayCondition && ( */}
      
        {/* )} */}
      </div>

      <div className="profile-containers">
        {personalInfoTab && (
          <PersonalInfo
            profileImage={displayPicture}
            candidateName={candidateName}
            middleName={middleName}
            lastName={lastname}
            emailId={emailId}
            designation={designation}
            title={title}
            dateOfBirth={dateOfBirth}
            pan={pan}
            aadharNumber={aadharNumber}
            passportNumber={passportNumber}
            recordStatus={recordStatus}
            eId={eId}
            status={status}
            aadharCardImage={aadharCardImage}
            panCardImage={panCardImage}
            resume={resume}
            bloodGroup={bloodGroup}
            profileInfoDisplayCondition={profileInfoDisplayCondition}
            employeeId={employeeId}
            getApiDispatch={getApiDispatch}
            languageDetails={languageDetails}
          />
        )}

        {contactInfoTab && (
          <ContactInfo
            personalMail={personalMail}
            mobileNumber={mobileNumber}
            alternateNumber={alternateNumber}
            emergencyContactNumber={emergencyContactNumber}
            emergencyContactName={emergencyContactName}
            currentAddress1={currentAddress1}
            currentAddress2={currentAddress2}
            currentAddress3={currentAddress3}
            permanentAddress1={permanentAddress1}
            permanentAddress2={permanentAddress2}
            permanentAddress3={permanentAddress3}
            currentCountry={currentCountry}
            permanentCountry={permanentCountry}
            currentState={currentState}
            currentPinCode={currentPinCode}
            currentDistrict={currentDistrict}
            permanentDistrict={permanentDistrict}
            permanentState={permanentState}
            permanentPinCode={permanentPinCode}
            eId={eId}
            status={status}
            recordStatus={recordStatus}
            permanentAddressProof={permanentAddressProof}
            currentAddressProof={currentAddressProof}
            isSameComunicationAdderess={isSameComunicationAdderess}
          />
        )}

        {employeeIdentificationTab && (
          <EmployeeIdentification
            currentLocation={currentLocation}
            eCode={employeeId}
            dateOfJoining={dateOfJoining}
            department={department}
            designation={designation}
            level={level}
            band={band}
            experience={experience}
            clientName={clientName}
            workLocation={workLocation}
            reportingManagerName={reportingManagerName}
            eId={eId}
            status={status}
            recordStatus={recordStatus}
            hrbpName={hrbpName}
            hrbpRef={hrbpRef}
            totalExperience={totalExperience}
            previousExperience={previousExperience}
            organisationExperience={organisationExperience}
          />
        )}

        {financialInfoTab ? (
          <FinancialInfo
            pfNumber={pfNumber}
            uanNumber={uanNumber}
            bankName={bankName}
            accountNumber={accountNumber}
            accountHolderName={accountHolderName}
            ifscCode={ifscCode}
            insuranceNumber={insuranceNumber}
            eId={eId}
            status={status}
            recordStatus={recordStatus}
            cancelledCheque={cancelledCheque}
          />
        ) : educationalInfo ? (
          <EducationalInfo status={status} />
        ) : skills ? (
          <Skills status={status} recordStatus={recordStatus} />
        ) : employmentHistory ? (
          <EmploymentHistory status={status} isFresher={isFresher}  getApiDispatch={getApiDispatch}/>
        ) : profileInfo ? (
          <ProfileInfo
            summary={summary}
            getApiDispatch={getApiDispatch}
            emailId={emailId}
            candidateName={candidateName}
            middleName={middleName}
            lastName={lastname}
            summaryReminderCount={summaryReminderCount}
            eId={eId}
            status={status}
            resume={resume}  
            profileInfoDisplayCondition={profileInfoDisplayCondition}
            employeeId={employeeId}
            profileImage={displayPicture}
            designation={designation}
          />
        ) : (
          ""
        )}
      </div>
      <div className="d-flex justify-content-center mt-3 mb-3 ">
        {eId === loginId ? (
          <></>
        ) : (
          <>
            {status !== "Active" && status !== "Inactive" ? (
              <>
                {CheckIsHrManager && (
                  <>
                    <div className="col-auto">
                      <Button
                        title="Approve"
                        className="maker-checker-btn"
                        onClick={() => {
                          handleStatus("Approved");
                        }}
                      ></Button>
                    </div>
                  </>
                )}
                <>
                {employeeDbChecker && (
                  <div className="col-auto">
                    <Button
                      title="Re-Send"
                      className="maker-checker-outline-btn"
                      onClick={() => {
                        handleReason();
                      }}
                    ></Button>
                  </div>)
                 }
                </>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <ModalComponents
        show={showReason}
        onHide={hideModal}
        title="Reason for Re-send"
        modalBody={
          <ReasonForReSend
            hideModal={hideModal}
            apiCallCompleted={apiCallCompleted}
          />
        }
        className="modal-md"
      />
    </>
  );
};

export default Profile;
