export const Strings = {
  cancel: "Cancel",
  submit: "Submit",
  proceed: "Proceed",
  deleteText: "Delete",
  confirmDelete: "Are you sure you want to delete?",
  deleteSuccess: "Deleted Successfully!",
  editSuccess: "Edited Successfully!",
  addSuccess: "Added Successfully!",
  submitSuccess: "Submitted successfully!",
  approve: "Approve",
  reject: "Reject",
  confirm: "Confirm",
  rejectSuccess: "Rejected successfully!",
  reasonforRejection: "Reason for Rejection",
  viewFile: "View File",
  errorAccess: "You Cannot Edit This Module",
  noData: "No Data Found",
  uploadSuccess: "Uploaded successfully!",
  clickUpload: "Click To Upload",
  dragDrop: "or Drag & Drop",
  fileTypeSize: "Jpg, Png Or Gif (Max 5mb)",
  cliName: "Client Name",
  insuranceNumberName: "Insurance Number",
  bankNameLabel: "Bank Name",
  accountNumberLabel: "Account Number",
  accountHolderNameLabel: "Account Holder Name",
  ifscCodeLabel: "IFSC Code",
  checqueCancel: "Upload Cancelled Cheque",
  fileTypeSizeResume: "Pdf (Max 5mb)",
  resume_title: "Resume",
  permanenetAddressProof: "Upload Permanent Address proof",
  communicationAddressProof: "Upload Communication Address proof",

  //project reporsitory strings
  imageSize: "Image size (Max 5MB)",
  uploadFile: "Upload File",
  uploadInvoice: "Upload Invoice",
  uploadSow: "Upload SOW",
  uploadMsa: "Upload MSA",
  uploadPoDoc: "Upload PO Doc",
  invoiceDetails: "Invoice Details",
  projectRepo: "Resource Allocation",

  //profile strings
  personal_info_heading: "Personal Information",
  contact_info_heading: "Contact Information",
  employee_identification_heading: "Employment Information",
  employment_history_heading: "Employment History",
  work_achievements_heading: "Work Achievements",
  financial_information_heading: "Financial Information",
  educational_information_heading: "Educational Information",
  provident_fund_details_heading: "Provident Fund (PF) Details",
  banking_information_heading: "Banking Information",
  insurance_details_heading: "Insurance Details",
  skills_heading: "Skills",
  exportReport: "Export Report",
  template: "Template",
  bulk_upload: "Bulk Upload",
  resource_allocation: "Resource Allocation",
  project_repo: "Project Repository",
  bench_management: "Bench Management",
  remarks: "Remarks",
  documents: "Documents",
  document_category: "Document Category",
  preview_doc: "Preview Document",
  add_invoice: "+ Add New Invoice",
  invoice_no: "Invoice Number",
  back: "Back",
  project_name: "Project Name",
  document: "Documents",
  preview_document: "Preview Document",

  // employee_db_labels
  department: "Department",
  levelLabel: "Level",
  bandLabel: "Band",
  employee_title: "Title",
  first_name: "First Name",
  middle_name: "Middle Name",
  last_name: "Last Name",
  date_of_birth: "Date Of Birth",
  blood_group: "Blood Group",
  e_code: "E-Code",
  date_of_joining: "Date Of Joining",
  reporting_manager_name: "Reporting Manager Name",
  official_email: "Official Email",
  pan_number: "Pan",
  aadhar_no: "Aadhar Number",
  passport_no: "Passport",
  personal_email_iD: "Personal Email ID",
  mobile_number: "Mobile Number",
  alternate_number: "Alternate Number",
  address_line1: "Address line1",
  address_line2: "Address line 2",
  address_line3: "Address line 3",
  emergency_no: "Emergency Number",
  name_of_the_emergency_contact: "Name of the Emergency Contact",
  address_for_communication: "Address for Communication",
  permanent_address: "Permanent Address",
  personal_contact_details: "Personal Contact Details",
  address_information: "Address Information",
  current_address_1: "Current Address 1",
  current_address_2: "Current Address 2",
  current_state: "State",
  current_district: "District",
  current_pincode: "Pincode",
  current_country: "Country",
  perma_address: "Permanent Address",
  same_as_communication_address: "Same as communication address",
  permanent_address_1: "Permanent Address 1",
  permanent_address_2: "Permanent Address 2",
  skillmineMailid: "skill-mine.com",

  // employee reports strings
  employee_record_status_details: "Employee Details Status Report",
  employee_name: "Employee Name",
  status: "Status",
  details_status: "Details Status",
  filter_by: "Filter By",

  valid_mobile_no: "Please enter valid mobile number",
  valid_email_id: "Please enter valid email address",
  valid_alternate_no: "Please enter valid alternate number",
  valid_emergency_no: "Please enter valid emergency number",
  valid_pan_no: "Please enter valid Pan number",
  valid_aadhar_no: "Please enter valid Aadhar no.",
  valid_passport_no: "Please enter valid Passport no.",
  valid_pincode: "Please enter valid pincode",
  valid_company_name: "Please enter valid company name",
  valid_designation: "Please enter valid designation",
  valid_location: "Please enter valid location",
  valid_roles: "Please enter valid roles & responsibility",
  valid_description: "Please enter valid description",
  valid_skill: "Please enter valid skill",
  valid_branch: "Please enter valid branch name",
  valid_year: "Please enter valid year",
  valid_institute: "Please enter valid institute name",
  valid_board_name: "Please enter valid board name",
  valid_marks: "Please enter valid marks",
  valid_e_code: "Please enter valid employee code",
  valid_skillmine_email: "Please enter valid skillmine email id",
  valid_certificate: "Please enter valid certificate name",
  valid_trainer_Name: "Please enter valid trainer name",
  valid_courseLink: `Please enter valid course link in "https://www.example.com" format `,
  valid_courseFees: "Please enter valid course fees",
  valid_courseName: "Please enter valid course name",
  valid_majorSkills: "Please enter valid major skills",

  file_size_max_msg: "File size too large. Maximum size is 2 MB.",
  file_type_msg: "File type not allowed. Allowed types are JPEG, DOC, and PDF.",
  allowOnlyPdf: "File type not allowed. Allowed type is PDF.",
  upload_payslip: "Upload Payslip",
  uanNumberLabel: "Uan Number",
  pfNumber: "PF Number",

  // employee Db Add experince labels
  company_name: "Company Name",
  designation: "Designation",
  location_label: "Location",
  start_date: "Start Date",
  end_date: "End Date",
  roles_responsibility: "Roles & Responsibility",
  upload_resignation_acceptance: " Upload Resignation/Acceptance Letter",
  upload_experience_relieving: "Upload Experience/Relieving Letter",
  this_is_my_last_employer: "This is my last employer",
  designationLabel: "Designation",
  departmentLabel: "Department",
  pfNumberLabel: "PF Number",
  course_start_date: "Enter Course Start Date",
  course_end_date: "Enter Course End Date",
  // employeeDb Add Skills labels
  skill_name: "Skill Name",
  years_of_experience: "Years Of Experience",
  less_than_a_year: "Less than a year",
  proficiency_level: "Proficiency Level",

  // employee Db Add work experince labels
  recognition_type: "Recognition Type",
  description_label: "Description",
  date_label: "Date",
  upload_certificate: "Upload Certificate",

  // employee Db Add education qualification labels
  qualification: "Qualification",
  branch_Stream: "Branch/Stream",
  institue_school_college: "Institue/School/College",
  board_university: "Board/University",
  year_of_passing: "Year Of Passing",
  percentage_of_marks: "Percentage Of Marks/CGPA",

  //inseed master strings
  module_master: "Module Master",
  module_name: "Module Name",
  imageSize2mb: "File size (Max 2MB)",
  uploadSyllabus: "Upload Content/Syllabus",
  editButton: "Edit",
  deleteButton: "Delete",
  contentSyllabus: "Content/Syllabus",
  successCriteria: "Success Criteria",

  //assessment master strings
  assessmentMaster: "Assessment Master",
  selectBatch: "Select Batch",
  // inseed trainee master strings
  trainee_master: "Trainee Master",
  select_by_batch: "Select By Batch",
  filter_by_record_status: "Filter By Record Status",
  filter_by_batch_status: "Filter By Batch Status",
  no_data_found: "No Data Found",
  emergency_contact_no: "Emergency Contact Number",
  on_boarding_date: "On Boarding Date",
  certificate: "Certificate",
  agreement_letter: "Agreement Letter",
  offer_letter: "Offer Letter",

  // be a coach Strings
  be_a_coach: "Be a Coach",

  //Inseed Training Schedule
  training_Schedule: "Training Schedule",
  create_training_title: "Training Schedule - Create Training",
  trainer_name: "Trainer Name",
  training_name: "Training Name",
  modules_covered: "Modules Covered",
  evaluation_date: "Evaluation Date",
  modules: "Modules",
  action: "Action",
  filters: "Filters",
  create_training: "+ Create Training",
  select: "Select",
  selected: "Selected",
  technology_stream: "Technology / Stream:",
  duration: "Duration(Hour):",
  durationDays: "Duration(Days):",
  trainer_contact: "Trainer Contact No:",
  trainer_institute: "Training Institue:",
  content: "Content/Syllabus",
  success_criteria: "Success Criteria",

  // coaching reports
  coaching_report: "Coaching Report",
  coach_name: "Coach Name:",
  total_sessions: "Total Sessions:",
  total_active_sessions: "Total Active Sessions:",
  total_sessions_completed: "Total Sessions Completed:",
  average_rating: "Average Rating:",
  sessions_taken: "Sessions Taken",
  technical_specification_list: "Technical Specification List",
  active_sessions: "Active Sessions",
  sessions_completed: "Sessions Completed",
  avg_rating: "Average Rating",
  coachee_list: "Trainee List",

  //coaching requests strings
  coachingRequest: "Coaching Request",
  requestACoach: "Request A Coach",
  selectCoach: "Select Coach",
  requestSent: "Request Sent",
  coachingPurpose: "Purpose Of Coaching",
  requestSuccess: "Request sent successfully",
  approveSuccess: "Approved Successfully!",
  viewApplicationList: "View Application List",

  //coaching progress strings
  coachingProgress: "Coaching In-Progress",

  //Inseed Master and Final Evaluation
  inseed_master: "Inseed Master",
  trainee_name: "Trainee Name",
  Cgpa: "CGPA",
  final_status: "Final Status",
  client_feedback: "Client Feedback",
  onboarding_date: "Onboarding Date",
  final_evaluation: "Final Evaluation",
  previous_assessments: "Previous Assessments",
  more_assessments: "More Assessments",
  onHold: "On Hold",
  rejected: "Rejected",
  coachingPro: "Coaching Progress",
  session: "Session",
  sessionDateAndTime: "Session Date & Time",
  dueDateAndTime: "Due Date & Time",
  homeWorkLabel: "Home Work",
  viewAssignment: "View Assignment",
  remarksLabel: "Remarks",
  updateRating: "Updated Rating!",
  updatedHomeWork: "Updated Home Work!",
  ratingLabel: "Rating :",
  feedbackLabel: "Feedback :",
  fileSize: "File size (Max 2MB)",
  update: "Update",
  selectStatus: "Select Status",
  updateStatusSuccess: "Status updated Successfully!",
  assignment: "Assignment :",
  assignmentSubmission: "Assignment Submission :",
  StatusLabel: "Status :",
  createNewSession: "Create New Session",
  newSession: "New Session",
  addedSessionSuccessfully: "Added session successfully",
  assignmentUpload: "Assignment Upload",

  //skillCoins
  skill_heading: "Skill Coins",
  skill_update_label: " Skill Coin Value (1 Skill coin = 0.1₹)",
  nodata: "No Data Found",
  refNo: "Reference No.:",
  empId: " Emp. ID - ",
  desc: "It gives me great pleasure to inform you that you have been awarded with SkillCOINs under the Skillmine –Intrapreneur Engagement Policy,the details of which are as below:",
  assignDate: "Date of Assignment",
  noSkill: "Number of SkillCoins",
  bookValue: "Book Value of Shares (INR)",
  dateOfVesting: "Date of Vesting",
  aggreDesc:
    " All the terms of the SkillCOINs assigned will be governed by the Intrapreneur Engagement Policy, as approved and /or amended by the board.",
  forwarDesc:
    " Once again, we congratulate you on your journey towards being an intrapreneur and look forward to working with you.",
  bestWish: "With Best Wishes, ",
  skillName: "Skillmine Technology Consulting Pvt Ltd. ",
  signature_name: "Anant Agrawal",
  role: "Managing Director",
  validName: "Please enter valid name",
  //redeem skillcoin
  redeem_label: "Number of Skillcoins to be Redeemed",
  submit_label: " Submit",
  cancel_label: "Cancel",
  transaction_label: "Transaction History",
  download_label: "Download policy",
  report: "Export Report",
  redeem_skill_coins_label: "Redeem Skill Coins",

  //addEmployee
  Employee_label: "Employee Name",
  no_skill: " Number of Skillcoins to be assigned",
  assigned_Date: "Assigned Date",
  employee_code: "Employee Code",
  reject_label: "Reject",
  approve_label: "Approve",
  Reason: "Reason",
  ReasonForResignation: "Reason for Resignation",
  allocate: "Allocate",
  deallocate: "Deallocate",

  //internal Job Posting
  applicationList: "Application List",
  statusChangeSuccess: "Status Changed Successfully!",
  open: "Open",
  close: "Closed",
  view_referral_policy: "View Referral Policy",
  download_jd: "Download JD",
  view_job: "View Job",
  internalJobPosting: "Internal Job Posting",
  applyForIJP: "Apply for Internal Job",
  post_a_job_to_ijp: "Post a Job To IJP",
  fault_records_ijp: "Fault_Records_Post_Job_to_IJP.xlsx",
  jobCloseSuccess: "Job Closed successfully",
  jonOpenSuccess: "Job Opened successfully",
  sr_associate: "Sr. Associate",
  lead: "Lead",
  manager: "Manager",
  sr_manager: "Sr. Manager",
  avp: "AVP",
  vp: "VP",
  sr_vp: "Sr. VP",

  //Skill Links
  referral_list: "Referral List",
  view: "View",
  referred_by: "Referred By",
  referred_by_email: "Referred By Email Id",
  referral_amount: "Referral Amount",
  full_name: "Full Name",
  email_id: "Email Id",
  contact_number: "Contact Number",
  current_ctc: "Current CTC",
  notice_period: "Notice Period (In Days)",
  upload_resume: "Upload Resume",
  post_job_title: "Skill Links - Post A Job",
  post_a_job: "Post a Job",
  post_to_job: "Post to IJP",
  post_to_skilllinks: "Post to SkillLinks",
  job_title: "Job Title",
  work_mode: "Work Mode",
  job_category: "Job Category",
  enter_location: "Enter Location",
  work_level: "Work Level",
  experience: "Experience",
  major_skills: "Major Skills",
  description: "Description",
  upload_description: "Upload Job Description",
  upload: "Upload",
  mode_of_work: "Mode Of Work",
  ctc_range: "CTC Range",
  refer_resource_title: "Skill Links - Refer a Resource",
  select_status: "Select Status",
  Job_description: "Job Description",
  employeeId: "EMP ID",
  applySuccess: "Applied Successfully!",
  resourceName: "Resource Name :",
  whyInterested: "Why am I interested ?",
  aspiration: "My Career Aspiration for next 24 months :",
  uploadResume: "Upload Resume",
  applyNow: "Apply Now",
  eligibleReferralList: "Eligible Referral Payout List",
  //Inseed
  confirmAdd: "Are you sure you want to Add",
  sharePoint_link: "Sharepoint Link",
  upload_program_plan: "Upload Program Plan",
  all: "All",
  client: "Client",
  training_location: "Training Location",
  technology_Stream: "Technology Stream",
  technology_sub_stream: "Technology Sub-Stream",
  work_location: "Work Location",
  batch_size: "Batch Size",
  required_hc: "Required Headcount(By Client)",
  stipend_per_month: "Stipend(in INR per Month)",
  proposed_ctc: "Proposed CTC",
  training_cost: "Training Cost",
  tco_sub:
    "This includes training cost & lab cost, stipend, Infra and other applicable costs associated with this INSEED Program.",
  date_of_onBoarding: "Date of Onboarding",
  inseed_program_owner: "Inseed Program Owner",
  inseed_program_SPOC: "Inseed Program SPOC",
  batch_status: " Batch Status",
  inseed_batch: "Inseed Batch",
  stipend: "stipend",
  spoc: "Client SPOC",
  required_headCount: "Required Head Count",
  all_trainings: "All Trainings",
  //Employee Db certifications
  certficationsTitle: "Certification & Credentials",
  certification_name: "Certification Name",
  institute_body_name: "Institute Name/Certifying Body",
  board_affiliation_name: "Board/Univ/Affiliation",
  record_status: "Record Status",
  last_working_date: "Last Working Date",
  employee_designation: "Designation",
  yes: "Yes",
  no: "No",
  send_email_text: "Are you sure you want to Approve Details and Send Email to",
  emailSuccess: "Sent Email successfully",
  profile: "Profile",
  alternateDaysLabel: "Alternate Days",
  alternateDateTimeTo: "Alternate Date & Time : To",
  alternateDateTimeFrom: "Alternate Date & Time : From",
  preferredDays: "Preferred Days",
  preferredDaysTimeTo: "Preferred Date & Time : To",
  preferredDaysTimeFrom: "Preferred Date & Time : From",
  nonCheckerWarning: "Only Checkers can perform this action",
  jdNotUploaded: "JD not Uploaded for this job",
  approved_label: "Approved",
  draft_label: "Draft",
  updated_label: "Updated",
  resent_label: "Re-Sent",
  republished_label: "Re-Published",
  published_label: "Published",
  new_label: "New",
  resigned_label: "Resigned",
  absconded_label: "Absconded",
  undo_action: "Undo done successfully!",
  //Resource allocation Strngs
  resource_name: "Resource Name",
  estimated_end_date: "Estimated End Date",
  reporting_manager: "Reporting Manager",
  role_desc: "Role Description",
  utilization: "Utilization (%)",
  active_label: "Active",
  inactive_label: "Inactive",
  client_label: "Client",
  majorSkills_valid: "You can only enter up to 5 major skills.",
  inValidModuleAccess: "You Cannot Access This Module",
  clickFresher: "Click here if you are fresher",
  error_description_alternative: "Something Went Wrong !",
  Purpose_description: "Purpose / Description",
  accept: "Accept",
  current_designation: "Current Designation",
  training_mode: "Training Mode",
  enter_course_name: "Enter Course Name",
  institute_name: "Institute Name",
  course_fees: "Course Fees (In ₹)",
  internal_training: "Udemy",
  external_training: "External Training",
  tenure_in_days: "Tenure (In Days)",
  course_link: "Course Link",
  reason_rejection: "Reason for Rejection",
  rejected_reason_info:
    "* (Reason Will be sent directly to employee over mail)",
  hr_course_string:
    "Do you wish for HR to locate educational institutions that offer the specified course for your team?",
  //skill links
  employee_referral_history: "Employee Referral History",
  employee_referral_details: "Employee Referral Details",
  rating_title: "Rating",
  hrbp_name: "HRBP Name",
  Start_Date: "Start Date",
  End_Date: "End Date",
  date_of_relieving: "Date Of Relieving",
  select_new_client: "Select New Client",
  select_new_project: "Select New Project",
  current_client: "Current Client",
  current_project: "Current Project",
  learning_development_manager_requests:
    "Learning & Development Manager Requests",
  learning_development_hr_requests: "Learning & Development HR Requests",
  learning_development_finance_requests:
    "Learning & Development Finance Requests",
  select_by_status: "Select by Status",

  //Compenetency Planning

  competencyHeading: "Competency",
  questionsHeading: "Self Assessment Questions",
  technicalCompetenceHeading: "Technical Competence",
  ProblemSolvingAnalyticalSkillsHeading: "Problem Solving & Analytical Skills",
  AdaptabilityFlexibilityHeading: "Adaptability & Flexibility",
  ContinuousLearningDevelopmentHeading: "Continuous Learning & Development",
  CustomerFocusServiceOrientationHeading:
    "Customer Focus & Service Orientation",

  my_assessments: "My Assessments",
  cetifications: "Certifications",
  upload_max_error:"Upload only PDF file(maximum size allowed is 5MB)",
  update_resume:" Update Resume",

 summary_note :"Please click on submit to add the summary"
};

export const requiredFeild = { required: "*" };

export const skillTableHeader = [
  "Skill Name",
  "Years of Experience",
  "Proficiency Level",
];

export const workAchievementsHeader = [
  "Recognition Type",
  "Description",
  "Date",
];

export const employmentHistoryHeader = [
  "Company Name",
  "Designation",
  "Location",
  "Roles & Responsibility",
  "Date of Joining",
  "Date of Relieving",
];

export const educationalTableHeader = [
  "Qualification",
  "Branch / Stream",
  "Institute / School / College",
  "Board / University",
  "Year of Passing",
  "Percentage / CGPA",
];

export const certficationsTableHeader = [
  "Certification Name",
  "Institute Name / Certifying Body",
  "Board / Univ / Affiliation",
  "Year Of Passing",
  "Percentage / CGPA",
];

export const employeeDbFilters = [
  { title: "E-Code", value: "employeeId" },
  { title: "Employee Name", value: "candidateName" },
  { title: "Email ID", value: "emailId" },
  { title: "Contact", value: "mobileNumber" },
  { title: "Department", value: "department" },
  { title: "Designation", value: "designation" },
  { title: "Joining Date(DD/MM/YYYY)", value: "dateOfJoining" },
  { title: "Status", value: "status" },
  { title: "Details Status", value: "recordStatus" },
  {title: "Project Mapped Status",value:"projectMappedStatus"}
];

export const resourceAllocationFilters2 = [
  { title: "Resource Name", value: "resourceName" },
  { title: "E-Code", value: "resourceEmployeeId" },
  { title: "Start Date(DD/MM/YYYY)", value: "startDate" },
  { title: "End Date(DD/MM/YYYY)", value: "endDate" },
  { title: "Utilization(%)", value: "utilization" },
  { title: "Role Description", value: "roleDescription" },
  { title: "Reporting Manager", value: "reportingManager" },
  { title: "Manager E-Code", value: "reportingManagerEmployeeId" },
  { title: "Status", value: "recordStatus" },
];
export const resourceAllocationColumn1 = [
  "Location",
  "Sub Project Code",
  "Client",
  "Technology Type",
  "PO Number",
];
export const resourceAllocationColumn2 = [
  "Project Type",
  "HC Of Resource",
  "Actual HC",
  "PO Start Date",
  "PO End Date",
  "Status",
];

export const resourceAddingModal = {
  po_number: "PO Number",
  po_start_date: "PO Start Date",
  po_end_date: "PO End Date",
  currency_type: "Currency Type",
  billing_status: "Billing Status",
  billing_rate: "Billing Rate",
  remarks: "Remarks",
};

export const projectRepoFilters = [
  { title: "Region", value: "region" },
  { title: "Client", value: "client" },
  // { title: "Project Description", value: "projectDescription" },
  { title: "Project Type", value: "projectType" },
  { title: "Sub Project Code", value: "subProjectCode" },
  {title: "BU Head", value: "tdm"},
  { title: "HRBP", value: "hrbp" },
  { title: "PO Status", value: "poStatus" },
  { title: "Status", value: "recordStatus" },
  { title: "Project Code", value: "projectCode" },
  { title: "PO Number", value: "poNumber" },

];

export const invoiceDetailsColumns1 = [
  "Technology Type:",
  "Currency Type:",
  "Billing Status:",
  "Billing Rate:",
  "Approved HC:",
  "Actual HC:",
];
export const invoiceDetailsColumns2 = [
  "PO No:",
  "PO Start Date:",
  "PO End Date:",
  "On-Boarding Date:",
  "Off-Boarding Date:",
];

export const invoiceDetailsColumn4 = [
  "VAT:",
  "TDS:",
  "Total:",
  "Paid:",
  "Diff:",
  "Status:",
];

export const invoiceDetailsColumn3 = [
  "Invoice Date:",
  "Revenue Month:",
  "Base Amount:",
  "CGST",
  "SGST",
  "IGST",
];

export const benchManagementFilters = [
  { title: "Employee Name", value: "employeeName" },
  { title: "E-Code", value: "employeeCode" },
  { title: "Date of Joining(DD/MM/YYYY)", value: "dateOfJoining" },
  { title: "Last Project", value: "LastProject" },
  { title: "Estimated End Date(DD/MM/YYYY)", value: "endDate" },
  { title: "PO End Date(DD/MM/YYYY)", value: "poEndDate" },
  { title: "Manager E-Code", value: "reportingManagerEmployeeId" },
  { title: "Skills", value: "skills" },
  { title: "Reporting Manager", value: "reportingManager" },
];

export const NwBenchManagementFilters = [
  { title: "E-Code", value: "employeeCode" },
  { title: "Employee Name", value: "employeeName" },
  { title: "Added By(DD/MM/YYYY)", value: "addedBy" },
  { title: "Added On(DD/MM/YYYY)", value: "addedOn" },
];

export const employeeReferredHistoryFilters = [
  { title: "E-Code", value: "employeeId" },
  { title: "Employee Name", value: "candidateName" },
  { title: "Total Referrals", value: "TotalReferrals" },
  { title: "Accepted Candidates(%)", value: "AcceptedCandidates" },
  { title: "Rejected Candidates(%)", value: "RejectedCandidates" },
  { title: "Candidates in KIV(%)", value: "KIVCandidates" },
  // { title: "Referral Rating", value: "AverageRating" },
];

export const referredListFilters = [
  { title: "Candidate Name", value: "candidateName" },
  { title: "Candidate Email Id", value: "candidateEmailId" },
  { title: "Candidate Contact No.", value: "candidateContactNumber" },
  { title: "Job Id", value: "jobId" },
  { title: "Referred Date(DD/MM/YYYY)", value: "referredDate" },
  { title: "Referred Location", value: "referredLocation" },
  { title: "Remarks", value: "remarks" },
];

export const statusFilters = [
  "All Records",
  "Accepted",
  "New",
  "KIV",
  "Rejected",
];

export const benchManagementAllRecordsFilters = [
  { title: "Bench All Records",value:"all"},
  { title: "Inactive", value: "Inactive" },
  { title: "Within 30 days", value: "within30Days" },
  { title: "Within 60 days", value: "within60Days" },
  { title: "Within 90 days", value: "within90Days" },
];

export const EmployeeProjectHistory = [
  "Project Name",
  "Project Code",
  "Sub Project Code",
  "Resource Start Date",
  "Resource End Date",
];

export const documentHeadingColumn = [
  "Purchase Order",
  "Master Services Agreement",
  "Statement of Work",
];

export const invoiceColumn = ["Invoice Copy"];

export const addInvoiceErrorsValidations = {
  difference_amount: "Please enter valid difference amount",
  paid_amount: "Please enter valid paid amount",
  total_amount: "Please enter valid total amount",
  tds: "Please enter valid tds",
  vat: "Please enter valid vat",
  igst: "Please enter valid igst",
  sgst: "Please enter valid sgst",
  cgst: "Please enter valid cgst",
  baseAmount: "Please enter valid base amount",
  revenueMonth: "Please enter valid revenue month",
  invoiceNumber: "Please enter valid invoice number",
};

export const invoiceModalLabels = {
  invoiceNo: "Invoice No.",
  invoiceData: "Invoice Date",
  revenueMonth: "Revenue Month",
  baseAmount: "Base Amount",
  cgst: "CGST",
  sgst: "SGST",
  igst: "IGST",
  vat: "VAT",
  tds: "TDS",
  totalAmount: "Total Amount",
  paidAmount: "Paid Amount",
  diffAmount: "Difference Amount",
  status: "Status",
  paidDate: "Paid Date",
  remark: "Remark",
};

export const invoiceStatusDropdown = ["Paid", "Partially Paid", "Unpaid"];

export const projectDetailsLabels = {
  geographyLabel: "Region",
  locationLabel: "Location",
  clientNameLabel: "Client Name",
  projectNameLabel: "Project Name",
  projectTypeLabel: "Project Type",
  projectCodeLabel: "Project Code",
  subProjectCodeLabel: "Sub Project Code",
  serviceLineLabel: "Technology Type",
  deliveryManagerLabel: "BU Head",
  hrBusinessPartnerLabel: "HR Business Partner",
  projectStartDateLabel: "On-Boarding Date",
  projectEndDateLabel: "Off-Boarding Date",
  projectStatusLabel: "PO status",
  purchaseOrderNoLabel: "Purchase Order No.",
  poStartDateLabel: "PO Start Date",
  poEndDateLabel: "PO End Date",
  approvedHcLabel: "Approved HC",
  currencyTypeLabel: "Currency Type",
  billingStatusLabel: "Billing Status",
  billingRateLabel: "Billing Rate",
  spocPersonNameLabel: "Client SPOC Person Name",
  spocContactNumberLabel: "Client SPOC Contact Number",
  spocEmailIDLabel: "Client SPOC Email ID",
  remarksLabel: "Remarks",
};

export const addProjectErrorsValidations = {
  poAmount: "Please enter valid Billing Rate",
  approvedHc: "Please enter valid approved HC",
  poNumber: "Please enter valid PO number",
  techType: "Please enter valid tech type",
  hrbp: "Please enter valid name",
  tdm: "Please enter valid name",
  subProjectCode: "Please enter valid subproject code",
  projectCode: "Please enter valid project code",
  projectName: "Please enter valid project name",
  clientVal: "Please enter valid client name",
  location: "Please enter valid location",
  spocPersonName: "Please Enter valid Name",
  spocNumber: "Please Enter Valid Number",
  spocEmail: "Please Enter Valid Email",
};

export const geographyDropdown = ["IND", "KSA", "USA", "UK"];

export const projectTypeDropdown = [
  "Internal",
  "Managed Service",
  "Staffing",
  "Billable",
  "Non Billable",
];

export const techTypeDropdown = [
  "Cloud & Infra",
  "Cyber",
  "Digital",
  "Product",
];

export const addProjectStatusDropdown = [
  "New",
  "Ongoing",
  "Closed",
  "Extended",
  "No Extension",
  "Conversion",
];

export const poValueInDropdown = ["GBP", "INR", "SAR", "USD"];

export const billingRateDropdown = [
  "Per Hour",
  "Per Day",
  "Monthly",
  "Quarterly",
  "Half Yearly",
  "Yearly",
];

export const moduleMasterFilter = [
  { title: "Module Name", value: "moduleName" },
  { title: "Duration", value: "duration" },
  { title: "Trainer Name", value: "trainerName" },
  { title: "Contact Number", value: "trainerContactNumber" },
  { title: "Trainer Institute", value: "instituteName" },
  { title: "Success Criteria", value: "successCriteria" },
  { title: "Technology Stream", value: "technologyStream" },
];

export const addModuleErrorsValidations = {
  moduleNameRequired: "Module Data is a required feild",
  technologyStreamRequired: "TechnologyStream is a required feild",
  durationRequired: "Duration is a required feild",
  trainerNameRequired: "Trainer Name is a required feild",
  trainerName: "Enter a vaild Trainer Name",
  instituteName: "Enter a vaild Institute Name",
  trainerContactNumberRequired: "Trainer Contact Number is a required feild",
  trainerContactNumber: "Enter a vaild Contact Number",
};

export const moduleLabels = {
  moduleName: "Module Name",
  technologyStream: "Technology Stream",
  duration: "Duration(in Days)",
  trainerName: "Trainer Name",
  trainerContactNumber: "Trainer Contact Number",
  trainerInstitute: "Trainer Institute/Organization",
  syllabus: "Content/Syllabus",
  successCriteria: "Success Criteria",
};

export const moduleMasterDetailsColumns = [
  "Trainer Name:",
  "Technology/Stream:",
  "Duration(Days):",
  "Trainer Contact No:",
  "Training Institute:",
];

export const trainingMasterFilters = [
  { title: "Trainee Name", value: "resourceName" },
  { title: "Contact Number", value: "contactNumber" },
  { title: "Email ID", value: "personalEmail" },
  { title: "DOJ-INSEED(DD/MM/YYYY)", value: "startDate" },
  { title: "LWD-INSEED(DD/MM/YYYY)", value: "endDate" },
  { title: "Status", value: "recordStatus" },
];

export const skillDbFilters = [
  { title: "Employee Name", value: "candidateName" },
  { title: "E-Code", value: "employeeId" },
  { title: "Date of Joining(DD/MM/YYYY)", value: "dateOfJoining" },
  { title: "Month/Year of Joining", value: "MonthAndYear" },
  { title: "Total Skill Coins", value: "totalSkillCoins" },
];
export const EmployeeskillDbFilters = [
  { title: "Skillcoins", value: "skillcoins" },
  { title: "Assigned Date(DD/MM/YYYY)", value: "assignedDate" },
  { title: "Maturity Date(DD/MM/YYYY)", value: "maturityDate" },
  { title: "Status", value: "status" },
  { title: "Record Status", value: "recordStatus" },
];
export const inseedTrainingSchedule = [
  { title: "Name of the Program", value: "inseedBatch" },
  { title: "Start Date(DD/MM/YYYY)", value: "startDate" },
  { title: "End Date(DD/MM/YYYY)", value: "endDate" },
  { title: "Client Name", value: "client" },
  { title: "Technology Required", value: "technology" },
];

export const assessmentLabels = {
  assessmentDate: "Assessment Date",
  assessmentType: "Assessment Type",
  modulesCovered: "Modules Covered",
  remarks: "Remarks",
};

export const assessmentTypeDropdown = [
  "PBT",
  "CBT",
  "Interview",
  "Combination",
  "Other",
];

export const assessmentMasterFilter = [
  { title: "Assessment Number", value: "assessmentNumber" },
  { title: "Assessment Date(DD/MM/YYYY)", value: "assessmentDate" },
  { title: "Assessment Type", value: "assessmentType" },
  { title: "Modules Covered", value: "modulesCovered" },
  { title: "Record Status", value: "recordStatus" },
];

export const myAssessmentMasterFilter = [
  { title: "Competency Number", value: "competencyNumber" },
  { title: "Asessment Submission Date(DD/MM/YYYY)", value: "submissionDate" },
  { title: "Stream ", value: "stream" },
  { title: "Sub Stream", value: "subStream" },
];

export const CertificationFilter = [
  { title: "Competency Number", value: "competencyNumber" },
  {
    title: "Competency Submission Date(DD/MM/YYYY)",
    value: "competencySubmissionDate",
  },
  { title: "Certification name ", value: "certificationName" },
  { title: "Institute name", value: "instituteName" },
  { title: "Date of Completion(DD/MM/YYYY)", value: "completionDate" },
];

export const streamsFilter = [
  { title: "Stream Name ", value: "streamName" },
  { title: "Leader ", value: "leaderName" },
];
export const subStremsFilter = [
  { title: "Sub Stream  ", value: "subStreamName" },
];

export const coachingRequestsFilter = [
  { title: "Name Of The Coach", value: "candidateName" },
  { title: "Experience", value: "yearsOfExperience" },
  { title: "Expertise", value: "expertise" },
  { title: "Location", value: "location" },
  { title: "Status", value: "status" },
];

export const coachingRequestTableExpansionLabels = {
  location: "Location",
  fromDate: "Preferred Date & Time : From",
  toDate: "Preferred Date & Time : To",
  preferredDays: "Preferred Days",
  altFrom: "Alternate Date & Time : From",
  altTo: "Alternate Date & Time : To",
  altDays: "Alternate Days",
  remarks: "Remarks",
  profile: "Profile",
};

export const RequestCoachingFilter = [
  { title: "Employee ID", value: "employeeId" },
  { title: "Name of the Employee", value: "nameOfTheEmployee" },
  { title: "Coach Emp ID", value: "coachEmployeeId" },
  { title: "Name Of The Coach", value: "nameOfTheCoach" },
  { title: "Status", value: "status" },
  { title: "Details Status", value: "detailStatus" },
  { title: "Scheduled Date(DD/MM/YYYY)", value: "scheduledDate" },
  { title: "Purpose for the Coach", value: "purpose" },
];

export const beACoachFilter = [
  { title: "Name Of The Coach", value: "nameOfTheCoach" },
  { title: "Experience", value: "yearsOfExperience" },
  { title: "Expertise", value: "expertise" },
  { title: "Location", value: "location" },
  { title: "Status", value: "recordStatus" },
];

export const coachReportsFilter = [
  { title: "Name Of The Coach", value: "nameOfTheCoach" },
  { title: "Completed Session", value: "CompletedSession" },
  { title: "Active Session", value: "ActiveSession" },
];
export const AllTrainingsFilter = [
  { title: "Training Name", value: "trainingName" },
  { title: "Modules", value: "selectedModules" },
  { title: "Start Date(DD/MM/YYYY)", value: "trainingStartDate" },
  { title: "End Date(DD/MM/YYYY)", value: "trainingEndDate" },
];

export const inseedMasterFilters = [
  { title: "Inseed Batch", value: "inseedBatch" },
  { title: "Start Date(DD/MM/YYYY)", value: "startDate" },
  { title: "End Date(DD/MM/YYYY)", value: "endDate" },
  { title: "Client Name", value: "client" },
  { title: "Training Location", value: "location" },
  { title: "Technology Stream", value: "technology" },
  { title: "Status", value: "status" },
];

export const homeWorkStatusDropdown = ["Completed", "Rework"];

export const addSessionLabel = {
  topicName: "Topic Name",
  sessionDate: "Session Date",
  homeWork: "Home Work",
  dueDate: "Due Date",
};

export const coachingProgressFilter = [
  { title: "Name of the Coach", value: "nameOfTheCoach" },
  { title: "Purpose for Coaching", value: "purpose" },
  { title: "Requested Date(DD/MM/YYYY)", value: "requestedDate" },
];

export const employeeCoachingProgressFilter = [
  { title: "Name of the Employee", value: "nameOfTheEmployee" },
  { title: "Purpose for Coach", value: "purpose" },
  { title: "Requested Date(DD/MM/YYYY)", value: "requestedDate" },
];

//Bench Management
export const currencyTypeDropdown = ["GBP", "INR", "SAR", "USD"];
export const BillingRateDropdown = [
  "Per Hour",
  "Per Day",
  "Monthly",
  "Quarterly",
  "Half Yearly",
  "Yearly",
];

//Internal Job Posting
export const ijpJobApplicationFilter = [
  { title: "Job ID", value: "jobId" },
  { title: "Resource Name", value: "resourceName" },
  { title: "Email ID", value: "emailId" },
  { title: "Phone Number", value: "contactNumber" },
  { title: "Current CTC", value: "currentCtc" },
  { title: "Preferred Location", value: "preferredLocation" },
  { title: "Status", value: "status" },
];

export const selectStatusDropdown = [
  { title: "Accepted", value: 1 },
  { title: "Rejected", value: 2 },
  { title: "KIV", value: 3 },
];
export const eligibleReferralsStatusDropdown = [
  { title: "Not Paid", value: 0 },
  { title: "Paid", value: 1 },
];
export const modeOfWorkDropdown = [
  "Full Time",
  "Part Time",
  "Remote",
  "Hybrid",
];
export const jobCategoryDropdown = [
  "HR",
  "Finance",
  "CEO",
  "Cloud",
  "IT Infra",
  "IT Security",
  "Software Delivery",
  "Staffing and Administration",
  "Admin",
  "Legal",
  "Travel & Admin",
];
export const locationDropdown = [
  "Bengaluru",
  "Hyderabad",
  "KSA",
  "Pune",
  "USA",
  "Other",
];
export const workLevelDropdown = [
  "Sr. Associate",
  "Lead",
  "Manager",
  "Sr. Manager",
  "AVP",
  "VP",
  "Sr. VP",
];
export const SkillLinksFilters = [
  { title: "All", value: "All" },
  { title: "Approved", value: "Approved" },
  { title: "Draft", value: "Draft" },
  { title: "Updated", value: "Updated" },
  { title: "Rejected", value: "Rejected" },
  { title: "Open", value: "open" },
  { title: "Closed", value: "close" },
];
export const eligibleReferralPayoutFilter = [
  { title: "Employee Name", value: "employeeName" },
  { title: "E-Code", value: "employeeId" },
  { title: "Referred Job ID", value: "referredJobId" },
  { title: "Referred Job Title", value: "referredJobTitle" },
  { title: "Candidate Name", value: "candidateName" },
  { title: "Candidate Email", value: "candidateEmail" },
  { title: "Accepted Date(DD/MM/YYYY)", value: "acceptedDate" },
  { title: "Referral amount", value: "referralAmount" },
  { title: "Status", value: "status" },
];
export const addEmployeeSkillCoinsErrorsValidations = {
  employeeNameVal: "Employee Name is a required feild",
  assigned_dateVal: "Assigned Date is a required feild",
  skillcoinsVal: "This is a required field",
  skillcoinsRange: "The skills assigned should be in the range of 100-500",
};

export const addEmployeeValidations = {
  designationValid: "Please enter valid designation",
  mobileNumberValid: "Please enter valid mobile number",
  emailIdValid: "Please enter valid skillmine email id",
  employeeIdValid: "Please enter valid employee code",
  lastnameValid: "Please enter valid name",
  candidateNameValid: "Please enter valid name",
};
export const SkillLinksReferralListFilters = [
  { title: "Resouce Name", value: "fullName" },
  { title: "Email Id", value: "emailId" },
  { title: "Contact Number", value: "contactNumber" },
  { title: "Current CTC", value: "currentCtc" },
  { title: "Notice Period", value: "noticePeriod" },
  { title: "Status", value: "status" },
  { title: "Remarks", value: "remarks" },
];

export const SkillLinksStrings = {
  desclaimer_title1: "Candidate Rating System:",
  bullet_text1:
    " To maintain the high standards of our workforce, each referred candidate will be rated based on their qualifications and performance.",
  bullet_text2:
    " Your rating as a referrer will depend on the quality of candidates you refer.",
  bullet_text3:
    " High-quality referrals contribute to your rating and eligibility for referral bonuses.",
  desclaimer_title2: "Consequences for Referring Unqualified Candidates:",
  bullet_text4:
    " Referring candidates who do not meet the required qualifications may affect your rating.",
  bullet_text5:
    "Continued referral of unqualified candidates may result in a lower rating, affecting your eligibility for bonuses.",
  bullet_text6:
    "We encourage you to refer only those individuals who genuinely match the job requirements.",
  end_text:
    " Your commitment to referring exceptional candidates greatly contributes to our success. We value your contributions to our team and appreciate your dedication to maintaining the high standards of our company.",
};
export const SkillLinksValidationStrings = {
  fullname_error: "Please enter valid full name",
  emailId_error: "Please enter valid email",
  contactNumber_error: "Please enter valid contact number",
  location_error: "Please enter valid location",
  noticePeriod_error: "Please enter valid notice period",
  currentCTC_error: "Please enter valid current CTC",
  jobTitle_error: "Please enter valid job title",
  modeOfWork_error: "Please enter valid mode of work",
  jobCategory_error: "Please enter valid job category",
  experience_error: "Please enter valid experience",
  workLevel_error: "Please enter valid work level",
  referralAmount_error: "Please enter valid referral amount",
  ctc_range_error: "Please enter the CTC Range",
};
export const selectStatusDropdownSkillLinks = [
  { title: "Accepted" },
  { title: "Rejected" },
  { title: "KIV" },
];
export const addEmployeeLabels = {
  firstName: "First Name",
  title: "Title",
  middleName: "Middle Name",
  lastName: "Last Name",
  dateOfBirth: "Date Of Birth",
  Ecode: "E-code",
  officialEmail: "Official Email",
  mobileNumber: "Mobile Number",
  designation: "Designation",
  dateOfJoining: "Date Of Joining",
  reportingManagerName: "Reporting Manager Name",
};

export const applyJobValidation = {
  preferredLocValid: "Please enter valid location",
  currentCtcValid: "Please enter valid CTC",
};

export const applyJobLabels = {
  preferredLocation: "My preferred Location",
  currentCTC: "My Current CTC",
  interested: "Why am I interested?",
  relevantExperience: "Relevant Experience",
  totalExp: "Total Years of Experience",
  careerAspiration: "My Career Aspiration for next 24 months",
  updatedCredentials: "Yes. Spotlight has my latest updated credentials.",
  ijpCondition:
    "I understand that the IJP may not result into immediate promotion and salary review.",
};

export const ijpFilters = [
  { title: "All", value: "All" },
  { title: "Open", value: "open" },
  { title: "Closed", value: "close" },
];
export const ijpHRFilters = [
  { title: "All", value: "All" },
  { title: "Approved", value: "Approved" },
  { title: "Rejected", value: "Rejected" },
  { title: "Updated", value: "Updated" },
  { title: "Draft", value: "Draft" },
  { title: "Open", value: "open" },
  { title: "Closed", value: "close" },
];
export const applicationDetailsColumns1 = ["View Resume :"];
export const applicationDetailsColumns2 = [
  "Emp ID :",
  "Preferred Location :",
  "Total Years of Experience :",
  "Relevant Experience :",
  "Current CTC:",
];

export const inseedMasterValidationStrings = {
  inseedProgramOwner_error: "Please enter valid name",
  spoc_error: "Please enter valid Client Spoc",
  intakeHC_error: "Batch Size should not be greater than 50",
  ctc_error: "CTC should be in between 250000-600000",
  stipend_error: "Stipend should be in between 9000-30000",
  location_error: "Enter valid location",
};

export const inseedRecordStatusDropdown = [
  "Yet to Start",
  "Active/In Progress",
  "On Hold",
  "Completed",
  "Abandoned",
  "Merged (with Other INSEED)",
];
export const personalInfoValidations = {
  middleNameValid: "Please enter valid name",
  lastnameValid: "Please enter valid name",
  candidateNameValid: "Please enter valid name",
};

export const personalDetailsDropdown = ["Dr.", "Miss", "Mr.", "Ms.", "Mrs."];
export const bloodGroupDropdown = [
  "A+",
  "A-",
  "B+",
  "B-",
  "O+",
  "O-",
  "AB+",
  "AB-",
  "Any",
];

export const addressDetailsValidation = {
  permanentPinCodeValid: "Please enter valid pincode",
  currentPinCodeValid: "Please enter valid pincode",
  permanentAddress1Valid: "Please enter valid permanent address",
  currentAddress1Valid: "Please enter valid communication address",
};

export const empBasicDetailsValidations = {
  departmentValid: "Enter Valid Department Name",
};
export const pfDetailsValidations = {
  pfNumberValid: "Please enter valid Pf number",
  uanNumberValid: "Please enter valid UAN number",
  insuranceNumberValid: "Please enter valid insurance number",
};

export const yearsOfExperienceDropdown = [
  "Fresher",
  "Less than a year",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
];
export const workDetailsValidation = {
  clientNameValid: "Enter valid Client Name",
  workLocationValid: "Enter valid Work location",
};
export const bankDetailsValidations = {
  accountNumberValid: "Please enter valid account number",
  ifscCodeValid: "Please enter valid ifsc code",
  accountHolderNameValid: "Please enter valid name",
  bankNameValid: "Please enter valid name",
};
export const permananetCountryDropdown = ["India", "Saudi Arabia", "USA"];

export const ijpExportColumn = [
  "Job Title",
  "Job Category",
  "mode of job/Work Mode",
  "Experience",
  "Location",
  "Work Level",
  "Referral Amount",
  "Description",
];

export const workLevelArray = [
  "Sr. Associate",
  "Lead",
  "Manager",
  "Sr. Manager",
  "AVP",
  "VP",
  "Sr. VP",
];

export const workExperienceObj = {
  exp_4_10_years: "4-10 Years",
  exp_7_11_years: "7-11 Years",
  exp_8_15_years: "8-15 Years",
  exp_10_20_years: "10-20 Years",
  exp_12_20_years: "12-20 Years",
  exp_15_20_years: "15-20 Years",
  exp_15_30_years: "15-30 Years",
};

export const referalAmountObj = {
  referral5000: "5000",
  referral15000: "15000",
  referral25000: "25000",
};

export const postaJobExportCol = [
  "Job Title",
  "Job Category",
  "mode of job/Work Mode",
  "Experience",
  "Location",
  "Work Level",
  "Referral Amount",
  "CTC Range",
  "Description",
];

export const moduleMasterData = [
  "Module Name",
  "Content",
  "Duration",
  "Trainer Name",
  "Contact Number",
  "Trainer Institue/Organization",
  "Success Criteria",
  "Technology Stream",
];

export const beACoachAddLabels = {
  yearsOfExperienceLabel: "Years Of Experience",
  expertiseLabel: "Expertise",
  LocationLabel: "Location",
};

export const beACoachDropdown = ["Bangalore", "Delhi", "Hyderabad", "Mumbai"];

export const moduleMasterExportReport = [
  "Module Name",
  "Content",
  "Duration",
  "Trainer Name",
  "Contact Number",
  "Trainer Institue/Organization",
  "Success Criteria",
  "Technology Stream",
];

export const projectRepoExportReport = [
  "Region",
  "Client Name",
  "Project Name",
  "Project Description",
  "Project Type",
  "Project Code",
  "Sub Project Code",
  "Technology Type",
  "BU Head",
  "HRBP",
  "On-Boarding Date",
  "Off-Boarding Date",
  "PO Status",
  "PO No.",
  "PO Start Date",
  "PO End Date",
  "Approved HC",
  "Actual HC",
  "Currency Type",
  "Billing Status",
  "Billing Rate",
  "Client SPOC Person Name",
  "Client SPOC Contact Number",
  "Client SPOC Email ID",
  "Status",
  "Updated By Name",
  "Updated By E-Code",
  "Updated Date",
  "Approved By Name",
  "Approved By E-Code",
  "Approved Date",
  "Remarks",
];

export const resourceAllocationTotalExportReport = [
  "Region",
  "Client Name",
  "Project Name",
  "Project Type",
  "Project Code",
  "Sub Project Code",
  "Technology Type",
  "BU Head",
  "HRBP",
  "On-Boarding Date",
  "Off-Boarding Date",
  "PO Status",
  "PO No.",
  "PO Start Date",
  "PO End Date",
  "Approved HC",
  "Currency Type",
  "Billing Status",
  "Billing Rate",
  "Client SPOC Person Name",
  "Client SPOC Contact Number",
  "Client SPOC Email ID",
  "Status",
  "Approved By Name",
  "Approved By E-Code",
  "Approved Date",
  "Remarks",
];

export const AllResource = [
  "Sub Project Code",
  "Resource E - Code",
  "Resource name",
  "Date Of Joining",
  "Department",
  "Client name",
  // "Reporting Manager",
  "Project Code",
  "Resource HR Business Partner",
  "Resource Reporting Manager",
  "Project BU Head",
  "Resource Start Date",
  "Resource End Date",
  "Billing Rate",
  "Resource Status",
  "Employee Status",
 
  "Created By",
  "Created on",
  "Updated By",
  "Updated on"
];

export const coachingRequestDropdown = [
  "Complete",
  "Incomplete",
  "Yet to Schedule",
  "Scheduled",
];

export const beaCoachCheckerColumns = [
  {
    Header: "Name Of the Coach",
    accessor: "candidateName",
  },
  {
    Header: "Experience",
    accessor: "yearsOfExperience",
  },
  {
    Header: "Expertise",
    accessor: "expertise",
  },
  {
    Header: "Location",
    accessor: "location",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

export const beaCoachAllColumns = [
  {
    Header: "Name Of The Coach",
    accessor: "candidateName",
  },
  {
    Header: "Experience",
    accessor: "yearsOfExperience",
  },
  {
    Header: "Expertise",
    accessor: "expertise",
  },
  {
    Header: "Location",
    accessor: "location",
  },

  {
    Header: "Status",
    accessor: "status",
  },
];

export const resourceAllocationsTableColumn = [
  {
    Header: "Region",
    accessor: "geography",
  },
  {
    Header: "Client",
    accessor: "clientName",
  },
  {
    Header: "Project Description",
    accessor: "projectDescription",
  },
  {
    Header: "Project Type",
    accessor: "projectType",
  },
  {
    Header: "Sub Project Code",
    accessor: "subProjectCode",
  },
  {
    Header: "BU Head",
    accessor: "tdm",
  },
  {
    Header: "HRBP",
    accessor: "hrbp",
  },
  {
    Header: "Actual HC",
    accessor: "actualHc",
  },
  {
    Header: "Approved HC",
    accessor: "approvedHc",
  },
  {
    Header: "PO Status",
    accessor: "status",
  },
  {
    Header: "Status",
    accessor: "recordStatus",
  },
];

export const inseedMasterTableColumn = [
  {
    Header: "Inseed Batch",
    accessor: "inseedBatch",
  },
  {
    Header: "Start Date",
    accessor: "startDate",
  },
  {
    Header: "End Date",
    accessor: "endDate",
  },
  {
    Header: "Client Name",
    accessor: "client",
  },
  {
    Header: "Training Location",
    accessor: "location",
  },
  {
    Header: "Technology Stream",
    accessor: "technology",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Batch Status",
    accessor: "recordStatus",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "Remarks",
    accessor: "remarks2",
  },
];
export const inseedMasterMakerCheckerTableColumn = [
  {
    Header: "Inseed Batch",
    accessor: "inseedBatch",
  },
  {
    Header: "Start Date",
    accessor: "startDate",
  },
  {
    Header: "End Date",
    accessor: "endDate",
  },
  {
    Header: "Client Name",
    accessor: "client",
  },
  {
    Header: "Training Location",
    accessor: "location",
  },
  {
    Header: "Technology Stream",
    accessor: "technology",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Batch Status",
    accessor: "recordStatus",
  },
  {
    Header: "Remarks",
    accessor: "remarks2",
  },
];
export const inseedMastertechnologyOptions = [
  {
    value: "Cloud & IT Infrastructure, ",
    label: "Cloud & IT Infrastructure",
  },
  { value: "Cyber Security, Other", label: "Cyber Security" },
  { value: "Digital Transformation", label: "Digital Transformation" },
  { value: "Testing and Automation", label: "Testing and Automation" },
  { value: "Other", label: "Other" },
];
export const traineeMasterTable = [
  {
    Header: "Name of the Program",
    accessor: "inseedBatch",
  },
  {
    Header: "Start Date",
    accessor: "startDate",
  },
  {
    Header: "End Date",
    accessor: "endDate",
  },
  {
    Header: "Client Name",
    accessor: "client",
  },
  {
    Header: "Technology Required",
    accessor: "technology",
  },
  {
    Header: "Program Plan",
    accessor: "trainingPlan",
  },
];

export const skillLinksReferralListTable = [
  {
    Header: "Resource Name",
    accessor: "fullName",
  },
  {
    Header: "Email Id",
    accessor: "emailId",
  },
  {
    Header: "Contact Number",
    accessor: "contactNumber",
  },
  {
    Header: "Current CTC",
    accessor: "currentCtc",
  },
  {
    Header: "Notice Period",
    accessor: "noticePeriod",
  },
  {
    Header: "Resume",
    accessor: "resume",
  },
  {
    Header: "Remarks",
    accessor: "remarks",
  },
];

export const learningDevFilters = [
  { title: "Employee Name", value: "employeeName" },
  { title: "Training Type", value: "trainingType" },
  { title: "Training Mode", value: "trainingMode" },
  { title: "Current Designation", value: "currentDesignation" },
  { title: "Course Name", value: "courseName" },
  { title: "Skills", value: "majorSkills" },
  { title: "Purpose", value: "purpose" },
];

export const learningDevColumn1 = [
  "Training Type:",
  "Training Mode:",
  "Current Designation:",
  "Course Name:",
  "Client Name:",
];

export const learningDevColumn2 = [
  "Institue name:",
  "Start Date:",
  "End Date:",
  "Trainer Name:",
  "Course Fees:",
  "Status:",
  ,
];

export const learningStatus = [
  { title: "All Records", value: "All Records" },
  { title: "New", value: "new" },
  { title: "Approved", value: "approved" },
  { title: "Rejected", value: "rejected" },
];

export const competencyFormLabels = {
  streamLabel: "Select Stream",
  subStreamLabel: "Select Sub Stream",
  leaderLabel: "Leader",
  levelLabel: "Level",
  designationLabel: "Designation",
};

export const competencyValidationStrings = {
  stream_error: "Stream cannot be empty",
  subStream_error: "Sub stream cannot be empty",
  leader_error: "Leader cannot be empty",
  level_error: "Level cannot be empty",
  designation_error: "Designantion cannot be empty",
};

export const levelDropdown = ["Beginner", "Intermediate", "Advanced"];
