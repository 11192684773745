import { useEffect, useMemo, useState } from "react";
import Button from "../../../../component/Buttons/Button";
import { icons } from "../../../../assets";
import ModalComponents from "../../../../component/ModalComponent/ModalComponent";
import AddResource from "../ResourceAllocationFunctions/AddResource";
import SearchBar from "../../../../component/SearchBar/SearchBar";
import "./ResourceAdding.scss";
import "../ResourceAllocation.scss";
import * as actions from "../../../../Redux/Actions";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import FilterValues from "../../../../component/FieldFilter/FilterValues";
import EditResource from "../ResourceAllocationFunctions/EditResource";
import DeleteResource from "../ResourceAllocationFunctions/DeleteResource";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { FaSort } from "react-icons/fa";
import React from "react";
import ShowEntries from "../../../../component/ShowEntries/ShowEntries";
import { showErrorToastMessage } from "../../../../component/Toasts/Toasts";
import {
  resourceAddingModal,
  resourceAllocationFilters2,
} from "../../../../constant/Strings";
import TablePagination from "../../../../component/TablePagination/TablePagination";
import MainCard from "../../../../component/MainCard/MainCard";
import {
  Strings,
  resourceAllocationColumn1,
  resourceAllocationColumn2,
} from "../../../../constant/Strings";
import ProjectDetailsTab from "../../../../component/ProjectDetailsTab/ProjectDetailsTab";
import useLoadingIndicator from "../../../../component/Loader/Loader";
import * as XLSX from "xlsx";
import { timeDateFormat } from "../../../../component/FormatDate";
const ResourceAdding = (props: any) => {
  const {
    totalPages,
    onPage,
    handlePageChange,
    searchValue,
    handleSearch,
    filterValue,
    filterChange,
    totalEntries,
    projectId,
    info,
    onSearchClicked,
    getApiCall,
  } = props;

  const dispatch = useDispatch();
  const [totalRecords, setTotalRecords] = useState(0);
  const role = localStorage.getItem("role");
  const isMakerData = role?.includes("resource-maker");
  const isChekerData = role?.includes("resource-checker");

  const {
    loadingComponent,
    apiCallCompleted,
    showLoadingIndicator,
    hideLoadingIndicator,
  } = useLoadingIndicator();
  const [showExportReport, setShowExportReport] = useState(false);

  const routeType = localStorage.getItem("routeType");
  const routeTypeId = localStorage.getItem("routeTypeId");
  const [resourceDetails, setResourceDetails] = useState("");

  const [showAddResource, setShowAddResource] = useState(false);
  const handleAddResource = () => {
    setShowAddResource(true);
  };
  const hideAddResource = () => {
    setShowAddResource(false);
  };

  const [showEditResource, setShowEditResource] = useState(false);
  const hideEditResource = () => {
    setShowEditResource(false);
  };
  const { project_name } = Strings;
  const handleEditResource = (ele: any) => {
    setResourceDetails(ele);
    setShowEditResource(true);
  };
  const [deleteId, setDeleteId] = useState("");
  const [showDeleteResource, setShowDeleteResource] = useState(false);
  const handleDeleteResource = (id: any) => {
    setDeleteId(id);
    setShowDeleteResource(true);
  };
  const hideDeleteResource = () => {
    setShowDeleteResource(false);
  };

  const [projectData, setprojectData] = useState<any>({});

  const getProjectbyIdApiCall = () => {
    dispatch(
      actions.getProjectRepositoryByIdAction(
        projectId,
        (res: any) => {
              if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
              } else if (res?.code === 200) {
                setprojectData(res.data[0]);
              }
        }
      )
    );
  };

  useEffect(() =>{
    getProjectbyIdApiCall()
  },[projectId])
  const [expansion, setExpansion] = useState(false);
  const [rowid, setRowid] = useState("");
  const [order, setorder] = useState("ASC");
  function handleExpansion(id: any) {
    setRowid(id);
    setExpansion(!expansion);
  }

  const [isSorted, setisSorted] = useState(false);
  const [sortedData, setsortedData] = useState<any>([]);
  const sorting = (col: any) => {
    setisSorted(true);
    if (order === "ASC") {
      const sorted = [...data].sort((a: any, b: any) => {
        if (col == "resourceName") {
          if (a?.resource[0]?.candidateName < b?.resource[0]?.candidateName) {
            return 1;
          }
          if (a?.resource[0]?.candidateName > b?.resource[0]?.candidateName) {
            return -1;
          }
          return 0;
        } else {
          if (a[col] < b[col]) {
            return 1;
          }
          if (a[col] > b[col]) {
            return -1;
          }
          return 0;
        }
      });
      setsortedData(sorted);
      setorder("DSC");
    } else {
      const sorted = [...data].sort((a: any, b: any) => {
        if (a[col] < b[col]) {
          return -1;
        }
        if (a[col] > b[col]) {
          return 1;
        }
        return 0;
      });
      setsortedData(sorted);
      setorder("ASC");
    }
  };

  const {
    arrow_drop_down,
    export_report,
    hamburger_icon,
    edit_blue_icon,
    delete_icon,
  } = icons;
  const { exportReport, no_data_found, filters } = Strings;

  const checkerMakerResourceAddingColumns: any = [
    {
      Header: "Resource Name",
      accessor: "resourceName",
    },
    {
      Header: "E-Code",
      accessor: "resource[0]?.employeeId",
    },
    {
      Header: "Start Date",
      accessor: "startDate",
    },
    {
      Header: "Estimated End Date",
      accessor: "endDate",
    },
    {
      Header: "Utilization(%)",
      accessor: "utilization",
    },
    {
      Header: "Role Description",
      accessor: "roleDescription",
    },
    {
      Header: "Reporting Manager",
      accessor: "reportingManager",
    },
    {
      Header: "Manager E-Code",
      accessor: "manager[0]?.employeeId",
    },
    {
      Header: "Action",
      disabledSortBy: false,
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];

  const ResourceAddingColumns: any = [
    {
      Header: "Resource Name",
      accessor: "resourceName",
    },
    {
      Header: "E-Code",
      accessor: "resource[0]?.employeeId",
    },
    {
      Header: "Start Date",
      accessor: "startDate",
    },
    {
      Header: "Estimated End Date",
      accessor: "endDate",
    },
    {
      Header: "Utilization(%)",
      accessor: "utilization",
    },
    {
      Header: "Role Description",
      accessor: "roleDescription",
    },
    {
      Header: "Reporting Manager",
      accessor: "reportingManager",
    },
    {
      Header: "Manager E-Code",
      accessor: "manager[0]?.employeeId",
    },
    // {
    //   Header: "Action",
    //   disabledSortBy: false,
    // },
    {
      Header: "Status",
      accessor: "status",
    },
  ];

  const data = info;
  const columns = useMemo(
    () =>
      isChekerData || isMakerData
        ? checkerMakerResourceAddingColumns
        : ResourceAddingColumns,
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const valueArray1 = [
    projectData?.location ? projectData?.location : "-",
    projectData?.subProjectCode ? projectData?.subProjectCode : "-",
    projectData?.Client?.length ? projectData?.Client?.[0]?.clientName : "-",
    projectData?.techType ? projectData?.techType : "-",
    projectData?.poNumber ? projectData?.poNumber : "-",
  ];

  const valueArray2 = [
    projectData?.projectType ? projectData?.projectType : "-",
    projectData?.approvedHc ? projectData?.approvedHc : "-",
    projectData?.actualHc ? projectData?.actualHc : "-",
    projectData?.poStartDate ? timeDateFormat(projectData?.poStartDate) : "-",
    projectData?.poEndDate ? timeDateFormat(projectData?.poEndDate) : "-",
    projectData?.status ? projectData?.status : "-",
  ];

  const generateXLFile = () => {
    showLoadingIndicator();
    dispatch(
      actions.getResourceAllocationAction(
        projectId,
        1,
        totalEntries,
        "",
        "",
        (res: any) => {
          if (res.code === 200) {
            var ResourceAllocationData = [
              [
                "Resource Name",
                "Resource E-Code",
                "Date Of Joining",
                "Department",
                "Sub Project Code",
                "Project Code",
                "Start Date",
                "End Date",
                "Utilization(%)",
                "Role Description",
                "Reporting Manager",
                "Resource HR Business Partner",
                "Reporting Manager Id",
                "Status",
                "Remark",
                "PO No.",
                "PO Start Date",
                "PO End Date",
                "Currency Type",
                "Billing Status",
                "Billing Rate",
                "Created By",
                "Created on",
                "Updated By",
                "Updated on"
              ],
            ];

            res?.data.length > 0 &&
              res?.data?.forEach((item: any) => {
                let emptyArray: string[] = [];
                emptyArray.push(
                  item.resource[0]
                    ? item.resource[0]?.candidateName +
                        " " +
                        item.resource[0]?.middleName +
                        " " +
                        item?.resource[0]?.lastname
                    : "",
                  item.resource?.length ? item.resource[0]?.employeeId : "",
                  item.resource?.length ? timeDateFormat(item?.resource?.[0]?.dateOfJoining) : "",
                  item.resource?.length ? item.resource?.[0]?.department : "",
                  item.projectData?.[0]?.subProjectCode,
                  item.projectData?.[0]?.projectCode,
                  item.startDate ? timeDateFormat(item.startDate) : "",
                  item.endDate ? timeDateFormat(item.endDate) : "",
                  item.utilization ? item.utilization : "",
                  item.roleDescription ? item.roleDescription : "",
                  item.resource?.[0]?.resourceManagerData?.length
                    ? item?.resource[0]?.resourceManagerData[0]
                    ?.candidateName +
                    " " +
                    item?.resource[0]?.resourceManagerData[0]
                      ?.middleName +
                    " " +
                    item?.resource[0]?.resourceManagerData[0]
                      ?.lastname
                    : "",
                    item.resource?.[0]?.resourceHrbpData?.length
                    ? item?.resource[0]?.resourceHrbpData[0]
                    ?.candidateName +
                    " " +
                    item?.resource[0]?.resourceHrbpData[0]
                      ?.middleName +
                    " " +
                    item?.resource[0]?.resourceHrbpData[0]
                      ?.lastname
                    : "",
                  item?.resource?.[0]?.resourceManagerData?.[0]?.employeeId ?? "",
                  item?.recordStatus ? item?.recordStatus : "",
                  item.remarks ? item.remarks : "",
                  item.poNumber ? item.poNumber : "",
                  item.poStartDate ? timeDateFormat(item.poStartDate) : "",
                  item.poEndDate ? timeDateFormat(item.poEndDate) : "",
                  item.currencyType ? item.currencyType : "",
                  item.billingRate ? item.billingRate : "",
                  item.poAmount ? item.poAmount : "",
                  item.createdByData?.length
                    ? item?.createdByData[0]
                    ?.candidateName +
                    " " +
                    item?.createdByData[0]
                      ?.middleName +
                    " " +
                    item?.createdByData[0]
                      ?.lastname
                    : "",
                    item.createdAt ? timeDateFormat(item.createdAt) : "",
                    item.updatedByData?.length
                    ? item?.updatedByData[0]
                    ?.candidateName +
                    " " +
                    item?.updatedByData[0]
                      ?.middleName +
                    " " +
                    item?.updatedByData[0]
                      ?.lastname
                    : "",
                    item.updatedAt ? timeDateFormat(item.updatedAt) : "",
                );
                ResourceAllocationData.push(emptyArray);
              });

            var ResourceAllocation = XLSX.utils.aoa_to_sheet(
              ResourceAllocationData
            );

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              wb,
              ResourceAllocation,
              "Resource_Allocation_report.csv"
            );
            hideLoadingIndicator();
            XLSX.writeFile(wb, "Resource_Allocation_report.csv");
          }
        }
      )
    );
  };
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="mt-2">
          <ProjectDetailsTab
            navigateTo={
              routeType == "BenchMgmt"
                ? `/employee-projectdetails/${routeTypeId}`
                : "/resource-allocation"
            }
            tabHeading={project_name}
            headingValue={projectData?.projectName}
            firstColumnHeadings={resourceAllocationColumn1}
            firstColumnValues={valueArray1}
            secondColumnHeadings={resourceAllocationColumn2}
            secondColumnValues={valueArray2}
          />
        </div>
        {isMakerData && (
          <div className="btn-wrapper mt-3">
            <Button
              title="+ Add Resource"
              onClick={handleAddResource}
              className="add-employee-btn"
            />
          </div>
        )}
        <>
          <div className="mt-2">
            <div className="mt-3 buttonsize resource-allocation-filter-wrapper">
              <div className="searchbar-wrapper">
                <SearchBar
                  filter={searchValue}
                  setFilter={handleSearch}
                  onSearchClicked={onSearchClicked}
                />
              </div>
              <div className="filter-container">
                <select
                  id="select-filters"
                  value={filterValue}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    const selectedOption = resourceAllocationFilters2.find(
                      (item: any) => item.value === selectedValue
                    );
                    if (selectedOption) {
                      filterChange(selectedOption);
                    }
                  }}
                >
                  <option hidden>{filters}</option>
                  {resourceAllocationFilters2.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="export-report-wrapper">
                <img
                  src={hamburger_icon}
                  onClick={() => setShowExportReport(!showExportReport)}
                  alt="hamburger_icon"
                  height={30}
                  width={30}
                />
                {showExportReport && (
                  <div className="report-btn-wrapper">
                    <>
                      <div className="export-btn">
                        {showExportReport && (
                          <div>
                            <button onClick={generateXLFile}>
                              <img src={export_report} alt="export_report" />{" "}
                              {exportReport}
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                )}
              </div>
            </div>
            <FilterValues
              filterValueArray={filterValue}
              onFilterChange={filterChange}
            />
            {data.length > 0 ? (
              <>
                <div className="table table-responsive">
                  <table
                    className="table-main table table-sm mt-3"
                    {...getTableProps()}
                  >
                    <thead className="table-head">
                      <tr className="custom-table-head-row">
                        {columns.map((ele: any) => (
                          <React.Fragment key={ele.Header}>
                            <th
                              onClick={() => sorting(ele.accessor)}
                              className="table-head-row"
                            >
                              {ele.Header}
                              {ele.Header !== "Assignment Letter" &&
                              ele.Header !== "Action" &&
                              ele.Header !== "" &&
                              ele.Header != "Remark" ? (
                                <span>{<FaSort />}</span>
                              ) : (
                                " "
                              )}
                            </th>
                          </React.Fragment>
                        ))}
                      </tr>
                    </thead>

                    <tbody className="table-body" {...getTableBodyProps()}>
                      {data?.length === 0 ? (
                        <tr>
                          <td colSpan={12}>{no_data_found}</td>
                        </tr>
                      ) : (
                        (isSorted ? sortedData : data).map(
                          (ele: any, index: any) => (
                            <>
                              <tr className="table-body-row" key={ele?._id}>
                                <td
                                  onClick={() => handleExpansion(index)}
                                  className="text-start"
                                >
                                  <img
                                    src={arrow_drop_down}
                                    className={
                                      index === rowid
                                        ? expansion
                                          ? "rotate-icon"
                                          : ""
                                        : ""
                                    }
                                    alt="arrow_drop_down"
                                  />
                                  <span className="ms-3">
                                    {ele?.resource[0]?.candidateName +
                                      " " +
                                      ele?.resource[0]?.middleName +
                                      " " +
                                      ele?.resource[0]?.lastname}
                                  </span>
                                </td>
                                <td>{ele?.resource[0]?.employeeId}</td>
                                <td>{`${
                                  ele.startDate
                                    ? timeDateFormat(ele.startDate)
                                    : ""
                                }`}</td>
                                <td>{`${
                                  ele.endDate ? timeDateFormat(ele.endDate) : ""
                                }`}</td>
                                <td>{ele?.utilization}</td>
                                <td>{ele?.roleDescription}</td>
                                <td>
                                  {ele?.resource[0]?.resourceManagerData?.length ? ele?.resource[0]?.resourceManagerData[0]
                                    ?.candidateName +
                                    " " +
                                    ele?.resource[0]?.resourceManagerData[0]
                                      ?.middleName +
                                    " " +
                                    ele?.resource[0]?.resourceManagerData[0]
                                      ?.lastname : "-"}
                                </td>
                                <td>{ele?.resource?.[0]?.resourceManagerData?.[0]?.employeeId}</td>
                                {(isMakerData || isChekerData) && (
                                  <td>
                                    <div className="action-btn">
                                      <img
                                        src={edit_blue_icon}
                                        alt="edit_icon"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit"
                                        className="edit-icon"
                                        onClick={() => handleEditResource(ele)}
                                      />
                                      <img
                                        src={delete_icon}
                                        alt="delete-icon"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete"
                                        onClick={() =>
                                          handleDeleteResource(ele?._id)
                                        }
                                        className="edit-icon"
                                      />
                                    </div>
                                  </td>
                                )}
                                <td>
                                  {
                                    <div
                                      className={`${
                                        ele?.recordStatus === "New"
                                          ? "projectRepo-new-btn"
                                          : ele?.recordStatus === "Approved"
                                          ? "projectRepo-approved-btn"
                                          : ele?.recordStatus === "Rejected"
                                          ? "projectRepo-rejected-btn"
                                          : ele?.recordStatus === "Updated"
                                          ? "projectRepo-updated-btn"
                                          : ""
                                      }`}
                                    >
                                      {ele?.recordStatus}
                                    </div>
                                  }
                                </td>
                              </tr>
                              {index === rowid && expansion && (
                                <tr>
                                  <td colSpan={12}>
                                    <div className="d-flex justify-content-around">
                                      <div>
                                        <div className="invoice_heading">
                                          {resourceAddingModal.po_number}
                                        </div>
                                        <div>{ele?.poNumber} </div>
                                      </div>
                                      <div>
                                        <div className="invoice_heading">
                                          {resourceAddingModal.po_start_date}
                                        </div>
                                        <div>
                                          {ele.poStartDate
                                            ? timeDateFormat(ele?.poStartDate)
                                            : projectData?.poStartDate
                                            ? timeDateFormat(
                                                projectData?.poStartDate
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="invoice_heading">
                                          {resourceAddingModal.po_end_date}
                                        </div>
                                        <div>
                                          {ele?.poEndDate
                                            ? timeDateFormat(ele?.poEndDate)
                                            : projectData?.poEndDate
                                            ? timeDateFormat(
                                                projectData?.poEndDate
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="invoice_heading">
                                          {resourceAddingModal.currency_type}
                                        </div>
                                        <div>{ele?.currencyType} </div>
                                      </div>
                                      <div>
                                        <div className="invoice_heading">
                                          {resourceAddingModal.billing_status}
                                        </div>
                                        <div>{ele?.billingRate} </div>
                                      </div>
                                      <div>
                                        <div className="invoice_heading">
                                          {resourceAddingModal.billing_rate}
                                        </div>
                                        <div>{ele?.poAmount} </div>
                                      </div>
                                      <div>
                                        <div className="invoice_heading">
                                          {resourceAddingModal.remarks}
                                        </div>
                                        <div>
                                          {ele.remarks
                                            ? `${ele.remarks?.slice(0, 10)}...`
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </>
                          )
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="show-entries-wrapper">
                  <ShowEntries
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    totalRecords={totalRecords}
                    totalEntries={totalEntries}
                  />
                  <TablePagination
                    totalPages={totalPages}
                    currentPage={onPage}
                    onClick={handlePageChange}
                  />
                </div>
              </>
            ) : (
              <p className="no-data">{no_data_found}</p>
            )}
          </div>
        </>
        <div>
          <ModalComponents
            show={showEditResource}
            onHide={hideEditResource}
            title="Edit Resource Details"
            modalBody={
              <EditResource
                resourceDetails={resourceDetails}
                hideEditResource={hideEditResource}
                projectData={projectData}
                apiCallCompleted={apiCallCompleted}
                getApiCall={getApiCall}
              />
            }
            className="modal-lg"
          />

          <ModalComponents
            show={showAddResource}
            onHide={hideAddResource}
            title="Add Resource Details"
            modalBody={
              <AddResource
                hideAddResource={hideAddResource}
                apiCallCompleted={apiCallCompleted}
                getProjectbyIdApiCall={getProjectbyIdApiCall}
              />
            }
            className="modal-lg"
          />

          <ModalComponents
            show={showDeleteResource}
            onHide={hideDeleteResource}
            title="Delete Resource Details"
            modalBody={
              <DeleteResource
                id={deleteId}
                hideDeleteResource={hideDeleteResource}
              />
            }
            className="modal-lg"
          />
        </div>
        <ToastContainer />
      </MainCard>
    </>
  );
};
export default ResourceAdding;
